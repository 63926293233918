import React from 'react'
import { Field } from 'react-final-form'

import { formFields } from '../../../../../contants/contact'
import { getInputRenderer } from './renderInput'
import { FormValues } from '../types'

const PhoneField = () => {
  const renderInput = getInputRenderer<FormValues['phone']>({
    placeholder: 'Phone (optional)',
    type: 'tel',
  })

  return <Field name={formFields.phone} render={renderInput}></Field>
}

export default PhoneField
