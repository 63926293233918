import React from 'react'
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core'

const styles = ({
  palette: {
    primary: { contrastText },
  },
  breakpoints: { up },
}: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '3.0625rem',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [md]: {
        marginBottom: '6.75rem',
      },
      [xl]: {
        paddingTop: '4.4375rem',
        marginBottom: 0,
        maxWidth: '38.5rem',
        textAlign: 'left',
        alignItems: 'flex-start',
      },
    },
    title: {
      color: contrastText,
      marginBottom: '0.8125rem',
      [md]: {
        marginBottom: '1.3125rem',
      },
      [xl]: {
        fontSize: '5rem',
        lineHeight: '5.875rem',
        marginBottom: '1.625rem',
      },
    },
    description: {
      marginBottom: '1.4375rem',
      color: contrastText,
      [md]: {
        marginBottom: '2rem',
        fontSize: '0.9375rem',
        lineHeight: '1.5625rem',
      },
      [xl]: {
        fontSize: '1.25rem',
        lineHeight: '2.125rem',
        marginBottom: '2.75rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Body = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        Let&apos;s discuss
        <br />
        your project
      </Typography>
      <Typography className={classes.description}>
        Let&apos;s discuss more about how I can help your business.
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Body)
