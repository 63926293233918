import { FormValues } from '../components/Contact/ContactWidget/ContactForm/types'

type FormFieldNames = {
  [key: string]: keyof FormValues
}

export const formFields: FormFieldNames = {
  name: 'name',
  phone: 'phone',
  message: 'message',
  email: 'email',
}
