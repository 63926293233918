import React from 'react'
import { FieldRenderProps } from 'react-final-form'

import TextInput, { BaseProps as TextInputProps } from './TextInput'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const getInputRenderer = <Value extends unknown>(
  props: TextInputProps
) => {
  const TextInputWithFields = (fieldRenderProps: FieldRenderProps<Value>) => (
    <TextInput {...props} {...fieldRenderProps} />
  )

  return TextInputWithFields
}
