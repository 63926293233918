import React from 'react'
import { Field } from 'react-final-form'
import { formFields } from '../../../../../contants/contact'
import { FormValues } from '../types'
import { getInputRenderer } from './renderInput'

const NameField = () => {
  const renderInput = getInputRenderer<FormValues['name']>({
    placeholder: 'Name',
    type: 'text',
  })

  return <Field name={formFields.name} render={renderInput} />
}

export default NameField
