import React from 'react'
import { withStyles, WithStyles, createStyles, Button } from '@material-ui/core'

const styles = () => {
  const backgroundColor: string = '#F9944C'
  return createStyles({
    root: {
      backgroundColor,
      padding: '0.75rem',
      fontSize: '0.9375rem',
      lineHeight: '1.5625rem',
      width: '100%',
      color: 'black',
      '&:hover': {
        backgroundColor,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const SubmitButton = ({ classes }: Props) => {
  return (
    <Button type="submit" classes={classes}>
      Send
    </Button>
  )
}

export default withStyles(styles)(SubmitButton)
