import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  TextField,
  FilledTextFieldProps,
} from '@material-ui/core'
import { FieldRenderProps } from 'react-final-form'

const styles = () => {
  const inputPadding = '0.6875rem 1.375rem'
  return createStyles({
    root: {
      width: '100%',
      '& + &': {
        marginTop: '0.875rem',
      },
    },
    input: {
      padding: inputPadding,
      fontSize: '1rem',
      lineHeight: '1.625rem',
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.5)',
        opacity: 1,
      },
    },
    multiline: {
      padding: inputPadding,
    },
    inputMultiline: {
      padding: 0,
    },
  })
}

export interface BaseProps
  extends Omit<
    FilledTextFieldProps,
    'placeholder' | 'type' | 'classes' | 'variant'
  > {
  placeholder: string
  type: string
}

interface Props
  extends WithStyles<typeof styles>,
    FieldRenderProps<string>,
    BaseProps {}

const TextInput = ({
  classes,
  placeholder,
  type,
  input,
  meta: { touched, error },
  ...other
}: Props) => {
  const shouldShowError: boolean = touched && error
  const { root, ...inputClasses } = classes
  return (
    <TextField
      className={root}
      variant="filled"
      type={type}
      placeholder={placeholder}
      error={shouldShowError}
      helperText={shouldShowError ? error : null}
      InputProps={{
        classes: inputClasses,
        disableUnderline: true,
      }}
      {...other}
      {...input}
    />
  )
}

export default withStyles(styles)(TextInput)
