import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
} from '@material-ui/core'

import ContactForm from './ContactForm'

const styles = ({
  palette: {
    text: { secondary },
  },
  breakpoints: { up },
}: Theme) => {
  const md = up('md')
  const xl = up('xl')
  return createStyles({
    root: {
      backgroundColor: 'white',
      boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.12)',
      borderRadius: '0.6875rem',
      padding: '3.0625rem 1.125rem 1.8125rem',
      textAlign: 'center',
      [md]: {
        padding: '4.6875rem 3.375rem',
      },
      [xl]: {
        maxWidth: '29.6875rem',
      },
    },
    title: {
      marginBottom: '0.875rem',
      fontSize: '1.625rem',
      lineHeight: '1.875rem',
      [md]: {
        marginBottom: '0.875rem',
        fontSize: '1.9375rem',
        lineHeight: '2.25rem',
      },
    },
    description: {
      marginBottom: '1.75rem',
      color: secondary,
      [md]: {
        fontSize: '0.9375rem',
        lineHeight: '1.5625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const ContactWidget = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Contact me
      </Typography>
      <Typography className={classes.description}>
        I will get back to you within 24 hours.
      </Typography>
      <ContactForm />
    </div>
  )
}

export default withStyles(styles)(ContactWidget)
