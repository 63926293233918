import React from 'react'
import { Field } from 'react-final-form'

import { formFields } from '../../../../../contants/contact'
import { FormValues } from '../types'
import { getInputRenderer } from './renderInput'

const MessageField = () => {
  const renderInput = getInputRenderer<FormValues['message']>({
    placeholder: 'Message',
    type: 'text',
    rows: 8,
    multiline: true,
  })

  return <Field name={formFields.message} render={renderInput} />
}

export default MessageField
