import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core'

import NameField from './NameField'
import PhoneField from './PhoneField'
import MessageField from './MessageField'
import EmailField from './EmailField'

const styles = () => {
  return createStyles({
    root: {
      marginBottom: '1.6875rem',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Fields = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <NameField />
      <EmailField />
      <PhoneField />
      <MessageField />
    </div>
  )
}

export default withStyles(styles)(Fields)
