import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from '../../components/PageContainer'
import Body from './Body'
import ContactWidget from './ContactWidget'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

export const FLEX_BREAKPOINT_OF_CONTACT: Breakpoint = 'xl'

const styles = ({
  palette: {
    primary: { main: primaryColor },
  },
  breakpoints: { up, between },
}: Theme) => {
  const md = up('md')
  const flexBreakpoint = up(FLEX_BREAKPOINT_OF_CONTACT)
  return createStyles({
    root: {
      marginBottom: '5rem',
      minHeight: '53.375rem',
    },
    container: {
      paddingTop: '3rem',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: primaryColor,
        width: '100%',
        height: '41.5625rem',
        zIndex: -1,
        [md]: {
          height: '57.1875rem',
          borderRadius: '0.875rem',
          width: '39.25rem',
          left: '50%',
          transform: 'translateX(-50%)',
        },
        [flexBreakpoint]: {
          height: '42.1875rem',
          left: 0,
          width: 'auto',
          transform: 'none',
        },
      },
      [between('md', 'lg')]: {
        maxWidth: '29.6875rem',
        paddingTop: '3.8125rem',
      },
      [flexBreakpoint]: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '4.75rem',
        boxSizing: 'border-box',
        paddingLeft: '4.125rem',
        paddingRight: '7.75rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Contact = ({ classes }: Props) => {
  return (
    <section className={classes.root}>
      <PageContainer classes={{ root: classes.container }}>
        <Body />
        <ContactWidget />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Contact)
