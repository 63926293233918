import React from 'react'
import { Form } from 'react-final-form'
import { Config, FORM_ERROR, SubmissionErrors } from 'final-form'
import { navigate } from 'gatsby'

import Fields from './Fields'
import SubmitButton from './SubmitButton'
import { FormValues } from './types'
import apiConfig from '../../../../apiConfigs/config'
import { links } from '../../../../contants/links'
import { formFields } from '../../../../contants/contact'
import FormError from './FormErrror'

type ContactFormConfig = Config<FormValues>

type FormFieldName = keyof FormValues

const ContactForm = () => {
  const makeRequest = async (values: FormValues): Promise<void> => {
    const response = await apiConfig.post('/contact', values)
    if (response.status !== 200) {
      console.error(response)
      throw new Error('Contact request failed')
    }
  }

  const handleError = (error: unknown) => {
    console.error(error)
    return {
      [FORM_ERROR]:
        'Sorry, there was a problem. Please try contacting me through email at eduardo@supercoder.dev instead.',
    }
  }

  const onSubmit: ContactFormConfig['onSubmit'] = async (values) => {
    try {
      await makeRequest(values)
      navigate(links.confirm)
    } catch (error) {
      return handleError(error)
    }
  }

  const addValidationError = (values: Partial<FormValues>) => {
    return (
      errors: SubmissionErrors,
      name: FormFieldName
    ): SubmissionErrors => {
      if (!values[name]) {
        return {
          ...errors,
          [name]: 'This value is required.',
        }
      }
      return errors
    }
  }

  const validate: ContactFormConfig['validate'] = (
    values: Partial<FormValues>
  ) => {
    const requiredFields: FormFieldName[] = [
      formFields.name,
      formFields.message,
      formFields.email,
    ]
    return requiredFields.reduce(addValidationError(values), {})
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, hasSubmitErrors, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            {hasSubmitErrors && <FormError>{submitError}</FormError>}
            <Fields />
            <SubmitButton />
          </form>
        )
      }}
    ></Form>
  )
}

export default ContactForm
