import React from 'react'
import { Field } from 'react-final-form'

import { formFields } from '../../../../../contants/contact'
import { FormValues } from '../types'
import { getInputRenderer } from './renderInput'

const EmailField = () => {
  const renderInput = getInputRenderer<FormValues['email']>({
    placeholder: 'Email',
    type: 'email',
  })

  return <Field name={formFields.email} render={renderInput} />
}

export default EmailField
